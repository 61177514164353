import { HootenannyLogo } from "@/components/HootenannyLogo";
import { cn } from "@/lib/utils";

interface FooterProps extends React.HTMLAttributes<HTMLDivElement> {}

function Footer({ className, ...props }: FooterProps) {
    return (
        <div
            className={cn(
                "flex min-h-[192px] w-full flex-col items-center justify-center gap-y-[24px] bg-[#1C2E4C]",
                className,
            )}
            {...props}
        >
            <div className="flex gap-x-[8px]">
                <a
                    className="underline"
                    href="https://www.hootenanny.io/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                >
                    Privacy Policy
                </a>
                <span>|</span>
                <a
                    className="underline"
                    href="https://www.hootenanny.io/terms-of-business"
                    target="_blank"
                    rel="noreferrer"
                >
                    Terms of Service
                </a>
            </div>
            <HootenannyLogo />
        </div>
    );
}

export interface FooterContainerProps
    extends React.HTMLAttributes<HTMLDivElement> {
    footerProps?: FooterProps;
}

export function FooterContainer({
    children,
    className,
    footerProps,
    ...props
}: FooterContainerProps) {
    return (
        <div className={cn("flex min-h-dvh flex-col", className)} {...props}>
            <div className="w-full grow">{children}</div>
            <Footer {...footerProps} />
        </div>
    );
}
