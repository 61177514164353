import { ErrorBoundary } from "@sentry/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
    QueryClient,
    QueryClientProvider,
    useIsFetching,
} from "@tanstack/react-query";
import {
    createRootRoute,
    Outlet,
    ParseRoute,
    ScrollRestoration,
    useRouterState,
} from "@tanstack/react-router";
import React, { useEffect, useRef, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { ErrorPage } from "@/components/ErrorPage";
import { config } from "@/lib/config";
import { routeTree } from "@/routeTree.gen";

export type RoutePath = ParseRoute<typeof routeTree>["fullPath"];

export const Route = createRootRoute({
    component: Root,
});

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: { throwOnError: true },
    },
});

const TanStackRouterDevtools = import.meta.env.DEV
    ? React.lazy(() =>
          import("@tanstack/router-devtools").then((res) => ({
              default: res.TanStackRouterDevtools,
          })),
      )
    : () => null;

const ReactQueryDevtools = import.meta.env.DEV
    ? React.lazy(() =>
          import("@tanstack/react-query-devtools").then((res) => ({
              default: res.ReactQueryDevtools,
          })),
      )
    : () => null;

const stripe = loadStripe(config.stripePublishableKey);

function Root() {
    return (
        <QueryClientProvider client={queryClient}>
            <QueryClientContextOutlet />
        </QueryClientProvider>
    );
}

function QueryClientContextOutlet() {
    const [progress, setProgress] = useState(0);
    const mountingRef = useRef(false);
    const fetching = useIsFetching();
    const { isLoading } = useRouterState();

    useEffect(() => {
        mountingRef.current = true;
    }, []);

    useEffect(() => {
        if (!mountingRef.current) {
            setProgress(() => {
                if (fetching > 0 || isLoading) {
                    return 20;
                }

                return progress > 0 ? 100 : 0;
            });
        }

        mountingRef.current = false;
    }, [isLoading, fetching, progress]);

    return (
        <ErrorBoundary fallback={({ error }) => <ErrorPage error={error} />}>
            <Elements stripe={stripe}>
                <LoadingBar
                    color="#f9ef1e"
                    progress={progress}
                    onLoaderFinished={() => setProgress(0)}
                    waitingTime={100}
                    loaderSpeed={100}
                />
                <main>
                    <ScrollRestoration />
                    <Outlet />
                </main>
                <TanStackRouterDevtools
                    position="bottom-left"
                    toggleButtonProps={{
                        className:
                            "[&&]:opacity-50 [&&]:scale-75 [&&]:bottom-0 [&&]:-left-[15px]",
                    }}
                />
                <ReactQueryDevtools buttonPosition="bottom-right" />
            </Elements>
        </ErrorBoundary>
    );
}
