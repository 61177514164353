import * as Sentry from "@sentry/react";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { ErrorPage } from "@/components/ErrorPage";
import "@/globals.css";
import { routeTree } from "@/routeTree.gen";

const router = createRouter({
    routeTree,
    defaultNotFoundComponent: () => <ErrorPage kind="404" />,
});

declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router;
    }
}

const environment = import.meta.env["VITE_ENVIRONMENT"];

if (environment !== "production") {
    document.title = `[${environment}] ${document.title}`;
}

Sentry.init({
    dsn:
        environment !== "local"
            ? "https://e597f4feb36f28f65d18cecfeae79a24@o4508240980344832.ingest.de.sentry.io/4508240985915472"
            : undefined,
    integrations: [
        Sentry.tanstackRouterBrowserTracingIntegration(router),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/.*hootenanny\.io.*/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: environment,
    ignoreErrors: [
        "this.mediaController.media.webkitCurrentPlaybackTargetIsWireless",
        "Failed to fetch dynamically imported module",
        /^Importing a module script failed.$/,
        /^Network Error$/,
        /^Request failed with status code 403$/,
        /^Request failed with status code 404$/,
        /^Failed to load Stripe.js$/,
        /^Load failed$/,
    ],
});

const rootElement = document.getElementById("app")!;
if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <StrictMode>
            <RouterProvider router={router} />
        </StrictMode>,
    );
}
