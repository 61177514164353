import { AxiosError } from "axios";
import { z } from "zod";
import { artistSchema } from "@/lib/api/artist";
import { AbortOption, request } from "@/lib/api/request";

export async function getSubscriptions(options: AbortOption = {}) {
    const responseSchema = z
        .object({
            data: z
                .object({
                    data: z.array(
                        z
                            .object({
                                _id: z.string(),
                                performerInfo: artistSchema,
                            })
                            .transform(({ _id, performerInfo, ...rest }) => ({
                                id: _id,
                                artist: performerInfo,
                                ...rest,
                            })),
                    ),
                })
                .transform((res) => res.data),
        })
        .transform((res) => res.data);

    try {
        const res = await request("GET", "/subscriptions/user/search", {
            params: {
                // We don't want to paginate this
                limit: Number.MAX_SAFE_INTEGER.toString(),
            },
            signal: options.signal,
        });
        const subscriptions = responseSchema.parse(res);

        return subscriptions;
    } catch (e) {
        // Artists 403 on this endpoint, so just return empty subscriptions
        if (e instanceof AxiosError && e.status === 403) {
            return [] satisfies z.infer<typeof responseSchema>;
        }

        throw e;
    }
}
