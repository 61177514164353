import React from "react";
import { HootenannyLogo } from "@/components/HootenannyLogo";
import { cn } from "@/lib/utils";
import ArrowLeftGlyph from "@public/assets/icons/arrow-left-glyph.svg";

export interface NavigationBarProps
    extends React.ComponentPropsWithoutRef<"div"> {
    onBackButtonClick: (() => void) | null;
    backButtonChildren?: React.ReactNode;
}

export function NavigationBar({
    onBackButtonClick,
    backButtonChildren,
    className,
    ...props
}: NavigationBarProps) {
    return (
        <div
            className={cn(
                "sticky top-0 z-50 flex w-full justify-center bg-[#121e31] py-[16px] text-center shadow-[2px_2px_4px_2px_#00000059] md:text-left",
                className,
            )}
            {...props}
        >
            {onBackButtonClick && (
                <div className="absolute left-[20px] top-1/2 -translate-y-1/2 md:ml-[100px] lg:ml-[200px] xl:ml-[300px] 2xl:ml-[400px]">
                    <button
                        type="button"
                        onClick={() => onBackButtonClick()}
                        className="flex items-center justify-center gap-x-[10px]"
                    >
                        <img src={ArrowLeftGlyph} className="h-[16px]" />
                        {backButtonChildren}
                    </button>
                </div>
            )}
            <HootenannyLogo sizePx={30} />
        </div>
    );
}
