import { z } from "zod";
import { expr, StrictOmit } from "@/lib/utils";

const environmentsSchema = z.enum(["local", "staging", "demo", "production"]);
type Environment = z.infer<typeof environmentsSchema>;

type Config = {
    environment: Environment;
    apiBaseUrl: `http://${string}` | `https://${string}`;
    stripePublishableKey: `pk_${string}`;
};

const LOCAL_CONFIG = {
    apiBaseUrl: "http://localhost:8080",
    stripePublishableKey: "pk_",
} as const satisfies StrictOmit<Config, "environment">;

const STAGING_CONFIG = {
    apiBaseUrl: "https://staging.api.hootenanny.io",
    stripePublishableKey:
        "pk_test_51Py97nP7bwfrM9oKdFu52Xr1CKH7ZNyQh7QFFJXjwji8LW3fcJgKhVDtTnyNssAySGkr1oFh5G2bTIWKBzJTfrpy00Wk5pYCHu",
} as const satisfies StrictOmit<Config, "environment">;

const DEMO_CONFIG = {
    apiBaseUrl: "https://demo.api.hootenanny.io",
    stripePublishableKey:
        "pk_test_51QGKtsHp538LUAH7w2arDDawUruZKTae61gAJzuE3UJI3xNOY00vMe0o5B2LPjLSoVCDQKnbieH9jmrcCLn1fqeJ007OpuFHeC",
} as const satisfies StrictOmit<Config, "environment">;

const PRODUCTION_CONFIG = {
    apiBaseUrl: "https://fans.api.hootenanny.io",
    stripePublishableKey:
        "pk_live_51QGKtsHp538LUAH78AVGe8RJ3DWXiekqFJtrq1sYj7buIwUF9p85Re9yreKy1n0pHAUQdVRcbYAqvJORnSxk5xVE00GEIDY8wm",
} as const satisfies StrictOmit<Config, "environment">;

export const config = expr(() => {
    const environment = environmentsSchema.parse(
        import.meta.env["VITE_ENVIRONMENT"],
    );
    const targetEnvironment = environmentsSchema.parse(
        import.meta.env["VITE_TARGET_ENVIRONMENT"],
    );

    const config = {
        local: LOCAL_CONFIG,
        staging: STAGING_CONFIG,
        demo: DEMO_CONFIG,
        production: PRODUCTION_CONFIG,
    }[targetEnvironment];

    return {
        ...config,
        environment,
    };
});
