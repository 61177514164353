import { useNavigate, useParams } from "@tanstack/react-router";
import { isAxiosError } from "axios";
import React from "react";
import { FooterContainer } from "@/components/FooterContainer";
import { NavigationBar } from "@/components/NavigationBar";

export interface ErrorPageProps extends React.ComponentPropsWithoutRef<"div"> {
    kind?: "404" | "generic";
    error?: unknown;
}

export function ErrorPage({ kind, error }: ErrorPageProps) {
    const navigate = useNavigate();
    const { artistUsername } = useParams({
        strict: false,
    });

    const notFoundError = isAxiosError(error) && error.status === 404;
    const show404 = notFoundError || kind === "404";

    const navigateToHomePage = async () => {
        await navigate({
            to: artistUsername && !show404 ? "/$artistUsername" : "/",
            params: { artistUsername },
        });
        window.location.reload();
    };

    return (
        <FooterContainer>
            <NavigationBar
                onBackButtonClick={() => navigateToHomePage()}
                backButtonChildren={
                    <div className="hidden text-[16px] font-medium md:block">
                        Back to homepage
                    </div>
                }
            />
            <div className="mt-[100px] flex flex-col items-center">
                <ErrorPageContent
                    kind={show404 ? "404" : kind}
                    onHomePageClick={() => navigateToHomePage()}
                />
            </div>
        </FooterContainer>
    );
}

interface ErrorPageContentProps {
    kind: ErrorPageProps["kind"];
    onHomePageClick: () => void;
}

function ErrorPageContent({
    kind = "generic",
    onHomePageClick,
}: ErrorPageContentProps) {
    switch (kind) {
        case "404":
            return (
                <div className="flex max-w-[290px] flex-col gap-y-[10px] text-center leading-[26px] md:max-w-[360px] md:leading-[31px]">
                    <h1 className="text-[24px] font-bold md:text-[28px]">
                        Uh, oh, This page doesn’t seem to exist.
                    </h1>
                    <div>
                        <div className="text-[16px] leading-[20px]">
                            Head back to the{" "}
                            <button
                                className="text-[#F9EF1E] underline"
                                onClick={() => onHomePageClick()}
                            >
                                homepage
                            </button>
                            .
                        </div>
                        <div className="text-[16px] leading-[20px]">
                            That definitely exists.
                        </div>
                    </div>
                </div>
            );
        case "generic":
            return (
                <div className="flex max-w-[290px] flex-col gap-y-[20px] text-center leading-[26px] md:max-w-[360px] md:leading-[31px]">
                    <h1 className="text-[24px] font-bold md:text-[28px]">
                        Sorry. Something’s gone wrong.
                    </h1>
                    <div className="text-[16px] leading-[20px]">
                        Try refreshing the page or heading back to the previous
                        page.
                    </div>
                    <div className="text-[16px] leading-[20px]">
                        If you keep having problems, please{" "}
                        <a
                            className="text-[#F9EF1E] underline"
                            href="https://www.hootenanny.io/contact"
                            rel="noreferrer"
                            target="_blank"
                        >
                            report the issue
                        </a>{" "}
                        to our team.
                    </div>
                </div>
            );
    }
}
