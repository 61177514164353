import axios, { AxiosRequestConfig, Method } from "axios";
import { getToken } from "@/lib/api/auth";
import { config } from "@/lib/config";

export type PaginationOptions = {
    offset?: number;
    limit: number;
};

export type AbortOption = {
    signal?: AbortSignal;
};

export async function request(
    method: Method,
    endpoint: `/${string}`,
    options: {
        params?: Record<string, string | undefined>;
        data?: unknown;
        signal: AbortSignal | undefined | null;
        validateStatus?: AxiosRequestConfig["validateStatus"];
    },
) {
    const { params, data, signal, validateStatus } = options;
    const url = [config.apiBaseUrl, endpoint].join("");
    const token = getToken();

    const res = await axios.request<unknown>({
        adapter: "fetch",
        method: method,
        url: url,
        params: params,
        headers: {
            ...(token && {
                "Authorization": token,
            }),
        },
        data: data,
        signal: signal ?? undefined,
        validateStatus: validateStatus ?? ((status) => status < 400),
    });

    return res.data;
}

export class RequestError<TCode> extends Error {
    public readonly code: TCode;

    public constructor(code: TCode) {
        super();
        this.code = code;
    }
}
