import React from "react";
import { cn } from "@/lib/utils";

export type ButtonVariant = "blue" | "dark-blue";

export interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
    variant?: ButtonVariant;
    selected?: boolean;
    shadow?: boolean;
    children?: React.ReactNode;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            variant = "blue",
            selected = false,
            shadow = false,
            children,
            className,
            type = "button",
            ...props
        },
        ref,
    ) => {
        return (
            <button
                type={type}
                className={cn(
                    "rounded-[30px] px-[16px] py-[8px] text-[16px] font-medium text-white transition-opacity disabled:opacity-50",
                    {
                        "blue": "bg-[#1C2E4C]",
                        "dark-blue": "bg-[#121E31]",
                    }[variant],
                    selected && "bg-[#F9EF1E] text-[#050505]",
                    shadow && "shadow-default",
                    className,
                )}
                ref={ref}
                {...props}
            >
                {children}
            </button>
        );
    },
);
Button.displayName = "Button";
