import SocketIO, {
    ManagerOptions,
    Socket,
    SocketOptions,
} from "socket.io-client";
import { config } from "@/lib/config";

// Singleton
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class SocketService {
    private static instance?: Socket;

    private constructor() {}

    public static connect(token: string): Socket {
        if (!SocketService.instance) {
            SocketService.instance = SocketService.getSocket(token);
        }

        return SocketService.instance;
    }

    public static disconnect() {
        SocketService.instance?.close();
        SocketService.instance = undefined;
    }

    private static getSocket(token: string) {
        const options = {
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelay: 1 * 1000,
            reconnectionDelayMax: 10 * 1000,
            autoConnect: true,
            transports: ["websocket", "polling", "long-polling"],
            rejectUnauthorized: true,
            query: `token=${token}`,
        };

        const socket = SocketIO(
            config.apiBaseUrl,
            options as unknown as Partial<ManagerOptions & SocketOptions>,
        );

        socket.on("reconnect", () => {
            socket.emit("auth/login", { token });
        });

        return socket;
    }
}
