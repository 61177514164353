import React from "react";
import { z } from "zod";
import { cn, StrictOmit } from "@/lib/utils";
import SortingArrowsGlyph from "@public/assets/icons/sorting-arrows-glyph.svg";

export const sortOrderSchema = z.enum(["asc", "desc"]);
export type SortOrder = z.infer<typeof sortOrderSchema>;

export interface SortButtonProps
    extends StrictOmit<React.ComponentPropsWithoutRef<"button">, "onClick"> {
    sort: SortOrder;
    onClick: (sort: SortOrder) => void;
}

export function SortButton({
    sort,
    onClick,
    className,
    ...props
}: SortButtonProps) {
    return (
        <button
            type="button"
            className={cn("flex items-center gap-x-[14px]", className)}
            onClick={() => onClick(sort === "asc" ? "desc" : "asc")}
            {...props}
        >
            <img
                src={SortingArrowsGlyph}
                className={cn(
                    "ml-[8px] h-[14px] w-[22px]",
                    sort === "asc" && "-scale-x-100",
                )}
            />
            <div className="text-[16px]">
                {sort === "desc" ? "Newest" : "Oldest"}
            </div>
        </button>
    );
}
