import dedent from "dedent";
import { config } from "@/lib/config";

export function setCookie(name: string, value: string, ttl: number) {
    document.cookie = dedent`
        ${name}=${encodeURIComponent(value)}; path=/; Max-Age=${ttl}; SameSite=Strict; ${config.environment !== "local" && "Secure"}
    `;
}

export function getCookie(name: string) {
    for (const cookie of document.cookie.split("; ")) {
        const [cookieName, cookieValue] = cookie.split("=");

        if (cookieName === name) {
            return cookieValue?.length ? decodeURIComponent(cookieValue) : null;
        }
    }

    return null;
}

export function deleteCookie(name: string) {
    setCookie(name, "", 1);
}
