import { z } from "zod";
import { AbortOption, PaginationOptions, request } from "@/lib/api/request";
import { userSchema } from "@/lib/api/user";
import { Prettify } from "@/lib/utils";
import DefaultAvatar from "@public/assets/icons/default-avatar.svg";

export type Conversation = z.infer<typeof conversationSchema>;

const conversationSchema = z
    .object({
        _id: z.string(),
        lastMessage: z.string().optional(),
        lastMessageCreatedAt: z.string().datetime().optional(),
        recipientInfo: userSchema,
        totalNotSeenMessages: z.number(),
    })
    .transform(
        ({
            _id,
            recipientInfo,
            totalNotSeenMessages,
            lastMessage,
            lastMessageCreatedAt,
            ...rest
        }) => {
            const hasLastMessage =
                lastMessage !== undefined && lastMessageCreatedAt !== undefined;

            return {
                id: _id,
                recipient: recipientInfo,
                numUnreadMessages: totalNotSeenMessages,
                lastMessage: hasLastMessage
                    ? {
                          content: lastMessage,
                          utcCreationTimestamp: lastMessageCreatedAt + "[UTC]",
                      }
                    : null,
                ...rest,
            };
        },
    );

export type GetConversationsOptions = AbortOption;

export async function getConversations(options: GetConversationsOptions) {
    const responseSchema = z
        .object({
            data: z
                .object({
                    data: z
                        .array(conversationSchema)
                        .transform((conversations) =>
                            conversations.filter((c) => c.lastMessage !== null),
                        ),
                })
                .transform((res) => res.data),
        })
        .transform((res) => res.data);

    const res = await request("GET", "/conversations", {
        signal: options.signal,
        params: {
            // We don't want to paginate this
            limit: Number.MAX_SAFE_INTEGER.toString(),
        },
    });
    const conversations = responseSchema.parse(res);

    return conversations;
}

export async function startConversation(artistId: string) {
    const responseSchema = z
        .object({
            data: conversationSchema,
        })
        .transform((res) => res.data);

    const res = await request("POST", "/conversations", {
        signal: null,
        data: {
            source: "performer",
            sourceId: artistId,
        },
    });

    return responseSchema.parse(res);
}

export type Message = Awaited<ReturnType<typeof getMessages>>["data"][number];
export type GetMessagesOptions = Prettify<AbortOption & PaginationOptions>;

export async function getMessages(
    conversationId: string,
    options: GetMessagesOptions,
) {
    const responseSchema = z
        .object({
            data: z.object({
                data: z.array(
                    z
                        .object({
                            _id: z.string(),
                            text: z.string(),
                            createdAt: z.string().datetime(),
                            senderInfo: z
                                .object({
                                    _id: z.string(),
                                    avatar: z
                                        .string()
                                        .transform((avatar) =>
                                            avatar.length
                                                ? avatar
                                                : DefaultAvatar,
                                        ),
                                    name: z.string(),
                                    username: z.string(),
                                })
                                .transform(({ _id, avatar, ...rest }) => ({
                                    id: _id,
                                    avatarUrl: avatar,
                                    ...rest,
                                })),
                        })
                        .transform(
                            ({ _id, createdAt, senderInfo, ...rest }) => ({
                                id: _id,
                                utcCreationTimestamp: createdAt + "[UTC]",
                                author: senderInfo,
                                ...rest,
                            }),
                        ),
                ),
                total: z.number(),
            }),
        })
        .transform((res) => res.data);

    const res = await request(
        "GET",
        `/messages/conversations/${conversationId}`,
        {
            signal: options.signal,
            params: {
                offset: options.offset?.toString(),
                limit: options.limit.toString(),
                sort: "desc",
            },
        },
    );

    return responseSchema.parse(res);
}

export function sendMessage(conversationId: string, text: string) {
    return request("POST", `/messages/conversations/${conversationId}`, {
        signal: null,
        data: {
            text: text,
            fileIds: [],
            type: "text",
        },
    });
}

export function deleteMessage(id: string) {
    return request("DELETE", `/messages/${id}`, { signal: null });
}

export function markMessagesAsRead(conversationId: string) {
    return request("POST", `/messages/read-all/${conversationId}`, {
        signal: null,
    });
}

export async function getNumUnreadMessages(options: AbortOption = {}) {
    const responseSchema = z
        .object({
            data: z.object({
                total: z.number(),
            }),
        })
        .transform((res) => res.data.total);

    const res = await request("GET", "/messages/total-unread-messages", {
        signal: options.signal,
    });
    const numUnreadMessages = responseSchema.parse(res);

    return numUnreadMessages;
}
