import { cn } from "@/lib/utils";
import HootennanyWordmarkWhite from "@public/assets/icons/hootenanny-wordmark-white.png";
import HootenannyYellow from "@public/assets/icons/hootenanny-yellow.png";

export interface HootenannyLogoProps
    extends React.ComponentPropsWithRef<"div"> {
    hideWordmark?: boolean;
    sizePx?: number;
}

export function HootenannyLogo({
    hideWordmark = false,
    sizePx = 40,
    className,
    ...props
}: HootenannyLogoProps) {
    return (
        <div className={cn("flex items-center", className)} {...props}>
            <img
                src={HootenannyYellow}
                style={{
                    height: `${sizePx.toString()}px`,
                    width: `${sizePx.toString()}px`,
                }}
            />
            {!hideWordmark && (
                <img
                    src={HootennanyWordmarkWhite}
                    style={{
                        height: `${String(sizePx + 16)}px`,
                    }}
                />
            )}
        </div>
    );
}
