import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

// https://www.totaltypescript.com/concepts/the-prettify-helper
export type Prettify<T> = {
    [K in keyof T]: T[K];
} & {};

export type Merge<M, N> = Omit<M, Extract<keyof M, keyof N>> & N;
export type StrictOmit<T, K extends keyof T> = Prettify<Omit<T, K>>;
export type StrictExclude<T, K extends T> = Prettify<Exclude<T, K>>;
export type Falsy = false | 0 | "" | null | undefined;

/**
 * Merges Tailwind class names.
 * For conflicting class names, the last provided class name will take precedence.
 * @param inputs `clsx` class values.
 * @returns the merged class names.
 */
export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

/**
 * Pluralises `options.word` if the `count` is greater than 1.
 * @param count The number of elements for the word.
 * @param options.word The singular version of the word you wish to pluralise.
 * @param options.plural Override the word to return when `count` is greater than 1.
 * @returns The word in the correct plurality.
 */
export function pluralise(
    count: number,
    options: { word: string; plural?: string },
) {
    const plural = options.plural ?? `${options.word}s`;

    return count === 1 ? options.word : plural;
}

/**
 * A modulo function that handles negative numbers correctly.
 * @param n the numerator.
 * @param m the denominator.
 * @returns `n` mod `m`.
 */
export function mod(n: number, m: number) {
    return ((n % m) + m) % m;
}

/**
 * Strip HTML tags from a string.
 * @param s The string to strip HTML tags from.
 * @returns The string with stripped HTML tags.
 */
export function stripHtmlTags(s: string) {
    return s.replace(/(<([^>]+)>)/gi, "");
}

/**
 * Patch elements within a collection by a predicate.
 * @param elements The collection of elements.
 * @param options.predicate Return `true` in this function if the `element` is a match.
 * @param options.patchFn A function that returns the fields to patch for the matched element.
 * @returns A new collection with the patched elements.
 */
export function patch<T extends Record<PropertyKey, unknown>>(
    elements: T[],
    options: {
        predicate: (element: T) => boolean;
        patchFn: (matchedElement: T) => Partial<T>;
    },
) {
    const { predicate, patchFn } = options;

    return elements.map((element) => {
        if (!predicate(element)) {
            return element;
        }

        return {
            ...element,
            ...patchFn(element),
        };
    });
}

export function ucfirst(s: string) {
    const [first, ...rest] = s;

    return first ? [first.toUpperCase(), ...rest].join("") : s;
}

export function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function expr<T>(fn: () => T) {
    return fn();
}

export function assert(condition: boolean, message: string): asserts condition {
    if (!condition) {
        throw new Error(`Assertion failed: ${message}`);
    }
}

export function unreachable(message: string): never {
    throw new Error(`Invariant violated: ${message}`);
}
