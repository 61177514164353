import { z } from "zod";
import { request } from "@/lib/api/request";
import { deleteCookie, getCookie, setCookie } from "@/lib/cookies";
import { queryClient } from "@/routes/__root";

const TOKEN_COOKIE_KEY = "hootenanny";
const TOKEN_COOKIE_TTL_SECONDS = 31_536_000; // 1 year

export function setAuthToken(token: string) {
    setCookie(TOKEN_COOKIE_KEY, token, TOKEN_COOKIE_TTL_SECONDS);
}

export async function login(usernameOrEmail: string, password: string) {
    const responseSchema = z
        .object({
            data: z.object({
                token: z.string(),
            }),
        })
        .transform((res) => ({ token: res.data.token }));

    const res = responseSchema.parse(
        await request("POST", "/auth/login", {
            data: {
                username: usernameOrEmail,
                password: password,
                remember: true,
            },
            signal: null,
        }),
    );

    setAuthToken(res.token);

    return res;
}

export function forgotPassword(email: string, artistUsername?: string) {
    return request("POST", "/auth/users/forgot", {
        data: {
            email,
            artist: artistUsername,
        },
        signal: null,
    });
}

export function resetPassword(token: string, password: string) {
    return request("POST", "/auth/password-change", {
        params: { token },
        data: {
            password,
        },
        signal: null,
    });
}

export function logout() {
    deleteCookie(TOKEN_COOKIE_KEY);
    queryClient.clear();
}

export function getToken() {
    return getCookie(TOKEN_COOKIE_KEY);
}

export function isLoggedIn() {
    return getToken() !== null;
}
